.mobi {
  display: none;
}

@media (min-width: 375.05px) and (max-width: 480px) {
  html {
    font-size: 16px !important;
  }
  body header {
    margin: 0;
    padding: 0 !important;
  }
  body header .pc {
    display: none !important;
  }
  body header .open_mobi {
    background-color: #fff !important;
  }
  body header .mobi, body header .open_mobi {
    padding: 1.375rem 1.5rem !important;
    position: relative;
    display: block;
    padding-bottom: 2rem;
    border-radius: 0 0 0.8rem 0.8rem;
  }
  body header .mobi .top, body header .open_mobi .top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  body header .mobi .list, body header .open_mobi .list {
    position: static;
    z-index: 99;
    width: 100%;
  }
  body header .mobi .list ul, body header .open_mobi .list ul {
    display: block;
  }
  body header .mobi .list ul li, body header .open_mobi .list ul li {
    margin: 0;
    display: flex;
    justify-content: center;
  }
  body header .mobi .list ul li .nav-link, body header .open_mobi .list ul li .nav-link {
    font-weight: bold;
    width: 100%;
    display: block;
  }
  body header .mobi .list ul li .nav-link .c, body header .open_mobi .list ul li .nav-link .c {
    width: 100%;
    display: flex;
    justify-content: center;
    color: #000;
    font-size: 1.1rem;
  }
  body header .mobi .list ul li .nav-link .child, body header .open_mobi .list ul li .nav-link .child {
    position: static;
    width: 100%;
    margin: 0 auto;
    transform: translateX(0%);
    box-shadow: none;
    padding: 0;
  }
  body header .mobi .list .btn, body header .open_mobi .list .btn {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  body header .mobi .list .btn button, body header .open_mobi .list .btn button {
    width: 100%;
  }
  body .HomeView .module_ {
    padding: 0;
    flex-direction: column-reverse;
  }
  body .HomeView .module_ .home_text {
    padding: 0 1.875rem;
    width: 100%;
  }
  body .HomeView .module_ .home_text .title {
    text-align: center;
    font-size: 3.5rem;
  }
  body .HomeView .module_ .home_text .des {
    text-align: center;
    margin-bottom: 1.5rem;
  }
  body .HomeView .module_ .home_text .btn_group {
    display: block;
  }
  body .HomeView .module_ .home_text .btn_group button {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 0.9375rem;
  }
  body .HomeView .module_ .show {
    width: 100%;
  }
  body .HomeView .module_right {
    padding: 0;
    height: auto;
    display: block;
  }
  body .HomeView .module_right .imgs {
    width: 100%;
    height: auto;
    left: -9rem;
  }
  body .HomeView .module_right .list_box {
    padding: 0 1.875rem;
    padding-top: 12rem;
    width: 100%;
  }
  body .HomeView .module_right .list_box .title_ .line {
    opacity: 0;
  }
  body .HomeView .module_right .list_box .list_content .item {
    width: 100%;
  }
  body .HomeView .module_center {
    display: block;
    padding: 0 1.5rem;
  }
  body .HomeView .module_center .item:first-child {
    margin-bottom: 3rem;
  }
  body .HomeView .module_center .item {
    margin: 0 auto;
    width: 100%;
    text-align: center;
  }
  body .HomeView .module_center .item .imgs {
    width: 100%;
  }
  body .HomeView .module_center .item .text .title_text {
    font-size: 2.5rem;
  }
  body .HomeView .module_Roadmap .left_text {
    width: 100%;
  }
  body .HomeView .module_Roadmap .right_imgs {
    display: none;
  }
  body .HomeView .module_Roadmap .box {
    display: block;
    padding: 0 1.5rem;
  }
  body .HomeView .module_Partner {
    width: 100%;
    padding: 0 1.5rem;
    margin-top: 4rem;
  }
  body .HomeView .module_Partner .list .item:nth-last-child(3) img {
    width: 3.1875rem;
    height: 3.1875rem;
    -o-object-fit: contain;
       object-fit: contain;
  }
  body .HomeView .module_Partner .list .item:last-child {
    opacity: 0;
  }
  body .HomeView .module_Partner .list .item {
    border-radius: 0.8rem;
    width: 32.5%;
    height: 3.2rem;
    margin-bottom: 1rem;
  }
  body .HomeView .module_Partner .list .item a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  body .HomeView .module_Partner .list .item a img {
    width: 100%;
  }
  body .NftView .container {
    padding: 0 1.875rem;
    flex-direction: column;
  }
  body .NftView .container .nft_text {
    margin-top: 2rem;
    width: 100%;
  }
  body .NftView .container .nft_text .title {
    font-size: 2rem;
  }
  body .NftView .container .nft_text .show {
    flex-direction: column;
    align-items: center;
  }
  body .NftView .container .nft_text .show .imgs {
    margin-bottom: 1rem;
  }
  body .NftView .container .nft_img {
    width: 100%;
    height: auto;
    margin-top: 1rem;
  }
  body footer {
    flex-direction: column-reverse;
    padding: 0 1.5rem !important;
    margin-top: 2rem !important;
  }
  body footer div, body footer p {
    margin-bottom: 0.5rem;
  }
  body footer div a {
    margin: 0 0.5rem;
  }
  body footer p {
    margin-bottom: 2rem;
  }
}/*# sourceMappingURL=style.css.map */