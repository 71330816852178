header {
  margin: 2.375rem 0;
  display: flex;
}
header nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
header nav > a {
  width: 11.6875rem;
  display: flex;
}
header ul {
  display: flex;
  align-items: center;
}
header ul li:hover .child {
  display: block;
}
header ul li {
  margin-right: 1.5625rem;
}
header ul li a, header ul li div {
  font-family: Prompt;
  color: var(--light-blue, #E8F0F9);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: relative;
}
header ul li a .c, header ul li div .c {
  display: flex;
  align-items: center;
  height: 3.75rem;
}
header ul li a img, header ul li div img {
  margin-left: 0.625rem;
}
header ul li a .child, header ul li div .child {
  display: none;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 3.75rem;
  left: 50%;
  transform: translateX(-50%);
  width: 14.25rem;
  padding: 1.25rem;
  align-items: flex-start;
  gap: 10px;
  z-index: 99;
}
header ul li a .child div, header ul li div .child div {
  font-family: Prompt;
  color: var(--light-blue, #E8F0F9);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: relative;
  display: block;
  text-align: center;
  font-family: Mukta;
  color: var(--grey, #B1B1B1);
  text-align: center;
  font-size: 1.125rem;
}
header ul li a .child div:not(:last-child), header ul li div .child div:not(:last-child) {
  margin-bottom: 0.625rem;
}
header ul li a .child div:hover, header ul li div .child div:hover {
  color: var(--empty-text-field, #5C468B);
}
header button {
  width: 11.8125rem;
  height: 3.125rem;
  border-radius: 0.9375rem;
  background: var(--purple-gradient, linear-gradient(90deg, #7B99ED 28.13%, #9579BD 100%));
  border: none;
  color: var(--white, #FFF);
  font-family: Mukta;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
}

footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 1.25rem;
  padding-bottom: 2.375rem !important;
}
footer p {
  color: var(--light-blue, #E8F0F9);
  font-family: Prompt;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
footer div{
  display: flex;
  justify-content: space-between;
}
footer div a{
  margin-right: 0.75rem;
}
