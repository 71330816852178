* {
  margin: 0px;
  padding: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}

html,
body {
  min-height: 100%;
  overflow-x: hidden;
}

body {
  background: var(--bg-gradient, linear-gradient(180deg, #2f2064 0%, #0e0724 100%));
  position: relative;
}

body::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: -14.8125rem;
  display: block;
  width: 42.3125rem;
  height: 42.3125rem;
  flex-shrink: 0;
  border-radius: 677px;
  background: rgba(192, 143, 250, 0.4);
  filter: blur(250px);
}

.container {
  padding: 0 100px;
}

@font-face {
  font-family: Prompt;
  src: url("../static/fonts/Prompt/Prompt-Light.ttf");
}
/* @font-face {
  font-family: Inter;
  src: url('./static/fonts/inter-bureau-font/InterBureauBold-ZDKl.otf');
} */
/* @font-face {
  font-family: Inter;
  src: url('./static/fonts/inter-bureau-font/InterBureauCondensed-xe7V.otf');
} */
.title {
  font-family: Inter;
  font-size: 4rem;
  font-style: normal;
  font-weight: 800;
  margin-top: 0.8125rem;
  margin-bottom: 0.625rem;
}

.des {
  color: var(--light-blue, #e8f0f9);
  /* 18 Regular */
  font-family: Prompt;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 3.125rem;
}

/* .nft_::after{
  content: '';
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 42.375rem;
  bottom: -5rem;
  left: 0;
  background-image: url(./static//images/Group1.png);
} */