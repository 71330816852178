.HomeView {
  width: 100%;
}

.HomeView .module_ {
  display: flex;
  justify-content: space-between;
  margin-top: 6rem;
  padding-left: 5.9375rem;
}

.HomeView .module_ .home_text {
  width: 48.1875rem;
}

.HomeView .module_ .home_text .title {
  background: var(--purple-gradient, linear-gradient(90deg, #7b99ed 28.13%, #9579bd 100%));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-size: 4.5rem;
  line-height: 4.9004375rem;
  margin-bottom: 3.125rem;
  font-family: Inter;
  height: 9.9375rem;
}

.HomeView .module_ .home_text .btn_group {
  display: flex;
  align-items: center;
}

.HomeView .module_ .home_text .btn_group button:last-child {
  color: var(--purple, #c08ffa);
}

.HomeView .module_ .home_text .btn_group button:last-child img {
  margin-right: 0.625rem;
}

.HomeView .module_ .home_text .btn_group button {
  /* 20 Medium */
  font-family: Prompt;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 0.9375rem;
  border: 2px solid var(--purple, #c08ffa);
  padding: 0.625rem 1.25rem;
  display: flex;
  align-items: center;
  margin-right: 1.5625rem;
  box-sizing: border-box;
  background-color: transparent;
}

.HomeView .module_ .home_text .btn_group button img {
  margin-left: 0.9375rem;
}

.HomeView .module_ .home_text .btn_group button:first-child {
  border: none;
  color: #fff;
  background: var(--purple-gradient, linear-gradient(90deg, #7b99ed 28.13%, #9579bd 100%));
}

.HomeView .module_ .home_text .btn_group button:last-child {
  color: var(--purple, #c08ffa);
}

.HomeView .module_ .home_text .btn_group button:last-child img {
  margin-left: 0.625rem;
}

.HomeView .module_ .home_text .btn_group div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--Purple, #c08ffa);
  /* 20 Medium */
  font-family: Prompt;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 0px;
  margin-left: 0px;
}

.HomeView .module_ .show {
  width: 34.875rem;
  animation: myMove 2s infinite;
  -webkit-animation: myMove 2s infinite;
  position: relative;
  top: 0px;
}

.HomeView .module_ .show img {
  width: 100%;
  margin-top: -4.5rem;
}

@keyframes myMove {
  0% {
    top: 0px;
  }

  50% {
    top: 18px;
  }

  100% {
    top: 0px;
  }
}

.HomeView .module_right {
  display: flex;
  position: relative;
  height: 46.4375rem;
  display: flex;
  justify-content: flex-end;
  padding-right: 6.25rem;
}

.HomeView .module_right .imgs {
  left: 0;
  position: absolute;
  width: 31.75rem;
  height: 46.4375rem;
}

.HomeView .module_right .imgs img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.HomeView .module_right .list_box {
  width: 57.9375rem;
}

.HomeView .module_right .list_box .title_ {
  margin-top: 1rem;
  width: 100%;
  font-family: Inter;
  font-size: 4.9004375rem;
  color: #7b99ed;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 800;
}

.HomeView .module_right .list_box .title_ .line {
  margin-right: 1.625rem;
  width: 25.5625rem;
  height: 0.0625rem;
  background: linear-gradient(90deg, #7b99ed 28.13%, #9579bd 100%);
}

.HomeView .module_right .list_box .title_ span {
  background: var(--purple-gradient, linear-gradient(90deg, #7b99ed 28.13%, #9579bd 100%));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.HomeView .module_right .list_box .list_content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.HomeView .module_right .list_box .list_content .item {
  border-radius: 0.9375rem;
  background: rgba(92, 70, 139, 0.3);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  display: flex;
  width: 18.4375rem;
  min-height: 15.3125rem;
  padding: 1.25rem;
  flex-direction: column;
  align-items: center;
  gap: 0.625rem;
  align-self: stretch;
  margin-top: 1.25rem;
  color: #fff;
  border: 1px solid #7b99ed;
}

.HomeView .module_right .list_box .list_content .item .item_title {
  color: var(--white, #fff);
  text-align: center;
  /* 18 Bold */
  font-family: Prompt;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0.625rem;
}

.HomeView .module_right .list_box .list_content .item .item_desc {
  color: var(--light-blue, #e8f0f9);
  text-align: center;
  font-family: PromptLight;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.HomeView .module_right::before {
  content: '';
  position: absolute;
  z-index: -1;
  bottom: -31rem;
  right: -14.8125rem;
  display: block;
  /* width: 42.3125rem; */
  height: 42.3125rem;
  flex-shrink: 0;
  border-radius: 677px;
  background: rgba(192, 143, 250, 0.4);
  filter: blur(250px);
}

.HomeView .module_center {
  margin-top: 14rem;
  display: flex;
  justify-content: center;
}

.HomeView .module_center .item:first-child {
  margin-right: 1.25rem;
}

.HomeView .module_center .item:first-child .imgs {
  width: 17.375rem;
}

.HomeView .module_center .item:last-child .imgs {
  width: 14.625rem;
}

.HomeView .module_center .imgs {
  height: 12.375rem;
  margin: 0 auto;
  margin-bottom: 1.125rem;
}

.HomeView .module_center .imgs img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.HomeView .module_center .item {
  width: 38.25rem;
  text-align: center;
}

.HomeView .module_center .item .text .title_text {
  background: linear-gradient(90deg, #7b99ed 28.13%, #9579bd 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 0.625rem;
  font-style: normal;
  font-weight: 800;
  /* line-height: 4.9004375rem; */
}

.HomeView .module_center .item .text .des_text {
  color: var(--light-blue, #e8f0f9);
  text-align: center;
  font-family: Prompt;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.HomeView .module_Roadmap {
  position: relative;
}

.HomeView .module_Roadmap h1 {
  text-align: center;
  font-size: 4.9004375rem;
  font-style: normal;
  font-weight: 800;
  line-height: 4.9004375rem;
  /* 100% */
  margin: 4.875rem 0 1.875rem 0;
  background: linear-gradient(90deg, #7b99ed 28.13%, #9579bd 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.HomeView .module_Roadmap .box {
  display: flex;
  justify-content: center;
  width: 100%;
}

.HomeView .module_Roadmap .left_text::after {
  content: '';
  display: block;
  position: absolute;
  top: 0.7rem;
  left: 0.7rem;
  height: 100%;
  width: 0.0625rem;
  background: #c08ffa;
}

.HomeView .module_Roadmap .left_text {
  width: 100%;
  /* margin-right: 4.875rem; */
  position: relative;
}

.HomeView .module_Roadmap .left_text .item {
  margin-bottom: 1.5625rem;
}

.HomeView .module_Roadmap .left_text .round {
  position: absolute;
  display: flex;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  border: 0.0625rem solid #c08ffa;
  align-items: center;
  justify-content: center;
}

.HomeView .module_Roadmap .left_text .round em {
  display: block;
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 50%;
  background: var(--purple-gradient, linear-gradient(90deg, #7b99ed 28.13%, #9579bd 100%));
  filter: drop-shadow(0px 0px 20px #c08ffa);
}

.HomeView .module_Roadmap .left_text .t {
  margin-left: 3.125rem;
}

.HomeView .module_Roadmap .left_text h5 {
  color: var(--white, #fff);
  font-family: Prompt;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  margin-bottom: 0.9375rem;
}

.HomeView .module_Roadmap .left_text ul li::before {
  content: '';
  display: inline-block;
  width: 0.2125rem;
  height: 0.2125rem;
  background-color: #fff;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.HomeView .module_Roadmap .left_text ul li {
  display: flex;
  align-items: center;
  color: #fff;
  font-family: Prompt;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.HomeView .module_Roadmap .right_imgs div:first-child {
  width: 19.4375rem;
  margin-left: 4rem;
  /* height: 26.125rem; */
  margin-bottom: 10rem;
}

.HomeView .module_Roadmap .right_imgs div:first-child img {
  width: 100%;
}

.HomeView .module_Roadmap .right_imgs div:last-child {
  width: 29.4375rem;
  height: 17.8125rem;
}

.HomeView .module_Roadmap .right_imgs div:last-child img {
  width: 100%;
}

.HomeView .module_Roadmap .bg {
  bottom: -10rem;
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 50.8125rem;
  background-image: url('../static//images/Group1.png');
}

.HomeView .module_Partner {
  width: calc(100% - 12.5rem);
  margin: 0 auto;
  margin-top: 10rem;
}

.HomeView .module_Partner h1 {
  text-align: center;
  font-size: 4.9004375rem;
  font-style: normal;
  font-weight: 800;
  line-height: 4.9004375rem;
  /* 100% */
  margin: 4.875rem 0 1.875rem 0;
  background: linear-gradient(90deg, #7b99ed 28.13%, #9579bd 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.HomeView .module_Partner .list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.HomeView .module_Partner .list .item:last-child {
  opacity: 0;
}

.HomeView .module_Partner .list .item {
  margin-bottom: 1.875rem;
  display: flex;
  width: 18.3125rem;
  height: 4.375rem;
  padding: 0px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 1.25rem;
  background: #fff;
}

.NftView {
  position: relative;
}

.NftView .bg {
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 42.375rem;
  bottom: -4.8rem;
  left: 0;
  background-image: url(../static//images/Group1.png);
  background-size: 100%;
}

.NftView .container {
  display: flex;
  justify-content: space-between;
}

.NftView .container .nft_text {
  width: 38.0625rem;
  margin-top: 2.5625rem;
}

.NftView .container .nft_text .title {
  font-family: Inter;
  font-size: 4rem;
  font-style: normal;
  font-weight: 800;
  margin-top: 0.8125rem;
  margin-bottom: 0.625rem;
  background: linear-gradient(90deg, #7b99ed 28.13%, #9579bd 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.NftView .container .nft_text .des {
  color: var(--light-blue, #e8f0f9);
  font-family: Prompt;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 3.125rem;
}

.NftView .container .nft_text .show {
  width: 100%;
  min-height: 19.8125rem;
  border: 0.225rem solid;
  border-image-source: linear-gradient(to right, #7b99ed, #9579bd);
  border-image-slice: 1;
  clip-path: inset(0 round 0.522rem);
  border-radius: 0.625rem;
  padding: 2rem 1.5rem;
  display: flex;
  justify-content: space-between;
}

.NftView .container .nft_text .show .imgs {
  width: 14.5625rem;
  height: 19.1875rem;
}

.NftView .container .nft_text .show .imgs img {
  width: 100%;
  height: 100%;
}

.NftView .container .nft_text .show .form {
  width: 19.75rem;
}

.NftView .container .nft_text .show .form .tab-buttons {
  width: 19.75rem;
  display: flex;
}

.NftView .container .nft_text .show .form .tab-buttons button {
  width: 9.875rem;
  height: 1.875rem;
  border: 0.06rem solid #9579bd;
  border-radius: 1rem;
  font-family: Prompt;
  font-size: 1.05rem;
  font-style: normal;
  font-weight: 400;
  margin-left: 0.365rem;
}

.NftView .container .nft_text .show .form .tab-buttons button {
  background-color: white !important;
}

.NftView .container .nft_text .show .form .tab-buttons button.active {
  background-color: #c08ffa !important;
}

.NftView .container .nft_text .show .form .item:not(:last-child) {
  margin-bottom: 1.4375rem;
}

.NftView .container .nft_text .show .form .item {
  display: flex;
  align-items: center;
}

.NftView .container .nft_text .show .form .item label {
  color: #fff;
  font-family: Prompt;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 9.875rem;
}

.NftView .container .nft_text .show .form .item p {
  color: #fff;
  font-family: Prompt;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.NftView .container .nft_text .show .form .item .b {
  width: 9.875rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.NftView .container .nft_text .show .form .item .b span {
  color: #fff;
  text-align: center;
  font-family: Prompt;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.NftView .container .nft_text .show .form .item .b img {
  width: 1.5rem;
  height: 1.5rem;
  -o-object-fit: contain;
  object-fit: contain;
}

.NftView .container .nft_text .show .form .item button {
  width: 100%;
  height: 3.3125rem;
  border-radius: 0.9375rem;
  background: var(--purple-gradient, linear-gradient(90deg, #7b99ed 28.13%, #9579bd 100%));
  border: none;
  color: var(--white, #fff);
  font-family: Mukta;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.NftView .container .nft_text .show .form .item:last-child {
  margin-top: 1.3125rem;
}

.NftView .nft_img {
  width: 29.0625rem;
  height: 46.25rem;
}

.NftView .nft_img img {
  width: 100%;
  height: 100%;
  animation: myMove 2s infinite;
  -webkit-animation: myMove 2s infinite;
  position: relative;
  top: 0px;
}

@keyframes myMove {
  0% {
    top: 0px;
  }

  50% {
    top: 18px;
  }

  100% {
    top: 0px;
  }
}
