@tailwind base;
@tailwind components;
@tailwind utilities;

.gradient-text-1 {
  text-align: center;
  background: linear-gradient(90deg, #7b99ed 28.13%, #9579bd 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.gradient-line {
  background: linear-gradient(90deg, #7b99ed 28.13%, #9579bd 100%);
}
